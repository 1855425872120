.alerts-drawer .ant-drawer-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #404040;
  font-size: 12px;
  font-weight: 400;
}

.label-drawer {
  font-weight: 400;
  font-size: 12px;
  color: #404040;
}