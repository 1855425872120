.no-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: calc(100dvh);
    background: linear-gradient(#06419A, #1264E0, #1264E0);
    padding: 32px;
    gap: 96px;
    text-align: center;
    font-family: var(--poppins);
    color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
}

.no-code-container>div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no-code-container p {
    max-width: 270px;
}

.no-code-container h1 {
    font-size: 48px;
    color: #fff;
    font-weight: 600;
    max-width: 450px;
    text-align: center;
    margin: 0;
}

.no-code-container__apps-btns {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 16px;
}

.no-code-container__apps-btns a {
    background-color: #000000;
    border: 1px solid #A6A6A6;
    color: #fff;
    border-radius: 8px;
    height: 40px;
    width: 140px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.no-code-container__apps-btns a:hover,
.no-code-container__apps-btns a:active,
.no-code-container__apps-btns a:focus {
    background-color: #000000;
    border: 1px solid #A6A6A6;
    color: #fff;
}

.no-code-container__try-apps {
    font-size: 12px;
}

.no-code-container__apps-btns a>svg {
    width: 26px;
    height: 26px;
}

.no-code-container__apps-btns a>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(100% - 26px);
}

.no-code-container__apps-btns a>div small {
    font-size: 10px;
    height: fit-content;
    line-height: 15px;
}

.no-code-container__apps-btns a>div p {
    font-size: 12px;
    height: fit-content;
    margin: 0;
    line-height: 15px;
    font-weight: 600;
}

@media screen and (min-width: 540px) {
    .no-code-container p {
        font-size: 22px;
        max-width: 400px;
    }

    .no-code-container img:not(#no-code-container__logo) {
        width: 230px;
    }

    .no-code-container #no-code-container__logo {
        width: 130px;
    }

    .no-code-container h1 {
        font-size: 56px;
        max-width: 475px;
    }

    .no-code-container__apps-btns a {
        height: 50px;
        width: 175px;
    }

    .no-code-container__apps-btns a>svg {
        width: 32px;
        height: 32px;
    }

    .no-code-container__apps-btns a>div {
        width: calc(100% - 32px);
    }

    .no-code-container__apps-btns a>div small {
        font-size: 12px;
        line-height: 18px;
    }

    .no-code-container__apps-btns a>div p {
        font-size: 14px;
        line-height: 18px;
    }

    .no-code-container__try-apps {
        font-size: 14px;
    }

}