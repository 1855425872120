.ant-table-tbody {
  background-color: #fff;
}

/* Custom Dropdown */
.more-options-standard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
}

.more-options-standard span {
  grid-column: 2 / 5;
}

/* Custom Switch */
.ant-switch-checked {
  background: var(--primary);
}

/***Custom Input (Text, TextArea) Ant design***/
.custom-input-label-hidden label {
  display: none !important;
}

.custom-input {
  border-radius: 6px !important;
  height: 38px;
  font-size: 15px;
  font-weight: 400;
  color: #7e7e7e;
}

.custom-input .ant-input,
.custom-input .ant-input::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #7e7e7e;
}

.custom-input-variant-1 {
  border: 1px solid #D6D5D5;
  border-radius: 6px;
  height: 35px;
  background-color: #F5F8FF;
}

.custom-input-variant-1 .ant-input {
  background-color:#F5F8FF;
}

/***Error en validaciones del formulario***/
.ant-form-item-explain-error {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
}

/***Custom Input Number Ant Design***/
.custom-input.ant-input-number {
  width: 100%;
}

.custom-input .ant-input-number-input-wrap,
.custom-input .ant-input-number-input-wrap input {
  height: 35px;
}

/***Custom Select Ant design***/
.custom-select .ant-select {
  display: flex !important;
  align-items: center !important;
}

.custom-select .ant-select-selector {
  border-radius: 6px !important;
  height: 34px !important;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}

.custom-select .ant-select-selector .ant-select-selection-item {
  height: 34px;
  text-align: left;
}

.custom-select .ant-select-selection-placeholder {
  color: #7e7e7e;
}

/***Custom Divider Ant Design***/
.custom-divider.ant-divider-horizontal.ant-divider-with-text::before,
.custom-divider.ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid transparent;
  border-top-color: #adadad;
}

/***Custom Checkbox Ant desing***/
.custom-checkbox {
  display: flex !important;
  align-self: flex-start !important;
  flex-direction: column;
  gap: 16px;
}

.custom-checkbox .ant-checkbox-group-item {
  display: flex !important;
  align-items: center !important;
}

.custom-checkbox .ant-checkbox {
  width: 24px !important;
  height: 24px !important;
  border: 1px solid #C2CDD5 !important;
  border-radius: 100%;
  background-color: #F0F4F7;
}

.custom-checkbox .ant-checkbox-wrapper.ant-checkbox-group-item .ant-checkbox-inner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #F0F4F7;
  width: 15px !important;
  height: 15px !important;
}

.custom-checkbox .ant-checkbox-wrapper-checked.ant-checkbox-group-item .ant-checkbox-inner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  background-color: var(--primary);
  width: 15px !important;
  height: 15px !important;
}

.custom-checkbox .ant-checkbox-checked::after {
  border-radius: 100%;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
}

.custom-checkbox .ant-checkbox-checked::after:hover {
  border: none !important;
}

/***Custom RadioGroup Ant Design***/

.custom-radio {
  display: flex !important;
  flex-direction: column;
  gap: 8px;
}

.custom-radio .ant-radio-wrapper {
  display: flex !important;
  align-items: center !important;
  height: 35px;
}

/*Cambiar el icono cuando no está chequeado el radio button*/
.custom-radio .ant-radio-wrapper .ant-radio .ant-radio-inner {
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
}

/*Cambiar el icono cuando está chequeado el radio button*/
.custom-radio .ant-radio {
  width: 20px !important;
  height: 20px !important;
}

.custom-radio .ant-radio,
.custom-radio .ant-radio.ant-radio-checked {
  border: 1px solid #C2CDD5 !important;
  border-radius: 100%;
  background-color: #F0F4F7;
}

.custom-radio .ant-radio-inner {
  transform: translate(19%, 19%);
  background-color: #F0F4F7;
}

.custom-radio .ant-radio-wrapper-checked .ant-radio .ant-radio-inner {
  transform: translate(19%, 19%);
  background-color: var(--primary) !important;
  border: none;
}

.custom-radio .ant-radio-inner {
  width: 13px !important;
  height: 13px !important;
}

.custom-radio .ant-radio-checked .ant-radio-inner::after {
  border: 1px solid #C2CDD5 !important;
}

.custom-radio .ant-radio-checked::after {
  background-color: var(--primary) !important;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
}

.custom-checkbox .ant-checkbox-checked::after:hover {
  border: none !important;
}

/***Custom DatePicker Ant Design***/

.custom-date-picker.ant-picker {
  height: 35px;
  width: 100%;
  border-radius: 6px;
}

.custom-date-picker.left-icon.ant-picker .ant-picker-suffix {
  order: -1;
  margin-left: 0;
  margin-right: 6px;
}

/***Custom RangerPicker Ant design***/
.custom-ranger-picker.ant-picker.ant-picker-range {
  height: 35px;
  width: 100%;
  border-radius: 6px;
}

.custom-ranger-picker .ant-picker-input input {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-ranger-picker.text-center .ant-picker-input input {
  text-align: center;
}

/**Mover de posicion el icono del calendario hacia la derecha**/
.custom-ranger-picker.ant-picker.ant-picker-range .ant-picker-suffix {
  order: -1;
  margin-left: 0;
  margin-right: 6px;
}

/***Custom TimePicker AntDesign***/
.custom-timepicker.ant-picker {
  border-radius: 6px;
  height: 35px;
  border: 1px solid #c9c9c9;
}

.custom-timepicker.ant-picker input {
  text-align: center;
}

/**Custom collapse**/
.custom-collapse {
  border-radius: 12px !important;
  padding: 16px !important;
  justify-content: space-between;
  background-color: #fff !important;
}

.custom-collapse .ant-collapse-content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.custom-collapse .ant-collapse-header {
  justify-content: space-between;
  width: 100%;
  color: #252525;
  font-size: 16px;
  font-weight: 600;
}

.custom-collapse .ant-collapse-header-text {
  width: 100%;
}

.custom-collapse .ant-collapse-expand-icon {
  order: 1;
}

/***Custom Table Ant Design***/

/***Custom Table Ant Design***/

.custom-table .ant-pagination.ant-table-pagination li {
  display: none;
}

.custom-table .ant-pagination.ant-table-pagination li:first-child,
.custom-table .ant-pagination.ant-table-pagination li:nth-child(2),
.custom-table .ant-pagination.ant-table-pagination li:last-child {
  display: block !important;
}

.custom-table .ant-pagination.ant-table-pagination li:nth-child(2) button,
.custom-table .ant-pagination.ant-table-pagination li:last-child button {
  background-color: transparent;
  border: none;
}


.custom-table table {
  border-collapse: separate !important;
  border-spacing: 0 20px !important; 
}

.custom-table tbody{
  position: relative;
  bottom: 1.5rem;
}

.custom-table tr{
  height: 80px;
}

.custom-table .ant-table-thead > tr > th, .ant-table{
  background-color: transparent !important;
}
.custom-table .ant-table-tbody > tr{
  background-color: #fff;
  box-shadow: 0px 6px 7px 0px #9AB9D440;
  padding-bottom: 2rem;
}
.custom-table .ant-table-tbody > tr > td:first-child{
  border-radius: 8px 0 0px 8px;
}
.custom-table .ant-table-tbody > tr > td:last-child{
  border-radius: 0px 8px 8px 0px;
}


.custom-ant-table .ant-pagination.ant-table-pagination li {
  display: none;
}

.custom-ant-table .ant-pagination.ant-table-pagination li:first-child,
.custom-ant-table .ant-pagination.ant-table-pagination li:nth-child(2),
.custom-ant-table .ant-pagination.ant-table-pagination li:last-child {
  display: block !important;
}

.custom-ant-table .ant-pagination.ant-table-pagination li:nth-child(2) button,
.custom-ant-table .ant-pagination.ant-table-pagination li:last-child button {
  background-color: transparent;
  border: none;
}

/***New Custom Table Ant Design ***/

.custom-new-table .ant-table-header .ant-table-cell,
.custom-new-table .ant-table-thead .ant-table-cell,
.custom-ant-table .ant-table-header .ant-table-cell,
.custom-ant-table .ant-table-thead>tr>th {
  background: #fff !important;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  color: #232323;
  text-align: center;
}

.custom-new-table .ant-table-tbody,
.custom-ant-table .ant-table-tbody {
  background-color: #fff;
}

.custom-new-table .ant-table-row,
.custom-ant-table .ant-table-row {
  font-size: 12px;
  font-weight: 400;
  color: #232323;
  text-align: center;
}

.custom-new-table .ant-table-row:nth-child(odd),
.custom-ant-table .ant-table-row:nth-child(odd) {
  background-color: #fff !important;
}

.custom-new-table .ant-table-row:nth-child(even),
.custom-ant-table .ant-table-row:nth-child(even) {
  background-color: #f3f3f3 !important;
}

.custom-new-table .ant-pagination.ant-table-pagination li {
  display: none;
}

.custom-new-table .ant-pagination.ant-table-pagination li:first-child,
.custom-new-table .ant-pagination.ant-table-pagination li.ant-pagination-item-active,
.custom-new-table .ant-pagination.ant-table-pagination li:last-child {
  display: block !important;
}

.custom-new-table .ant-pagination.ant-table-pagination li.ant-pagination-item-active button,
.custom-new-table .ant-pagination.ant-table-pagination li:last-child button {
  background-color: transparent !important;
  border: none !important;
}

.custom-new-table .ant-pagination.ant-table-pagination li.ant-pagination-item-active {
  height: 34px;
  width: 36px;
  margin: 0;
  border: 0.7px solid #c9c9c9;
}

.custom-new-table .ant-pagination.ant-table-pagination li.ant-pagination-item-active a {
  color: var(--primary);
}

.custom-new-table .ant-pagination-prev,
.custom-new-table .ant-pagination-jump-prev,
.custom-new-table .ant-pagination-jump-next {
  margin: 0;
}

.custom-new-table__btns {
  height: 34px;
  justify-content: center;
  align-items: center;
  border: 0.7px solid #c9c9c9;
  background-color: #f1f1f1;
  color: #7e7e7e;
  padding: 4px 8px;
  font-size: 14px;
}

.custom-new-table__btns.prev {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.custom-new-table__btns.next {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.custom-new-table__btns span {
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.custom-new-table__btns span:first-child {
  padding-right: 4px;
  border-right: 1px solid #d1d1d1;
}

.custom-new-table__btns span:last-child {
  padding-left: 4px;
}

/***Custom Notification Ant Design***/

.ant-notification.ant-notification-top:has(.custom-notification) {
  top: 0 !important;
  margin-right: 0;
}

.custom-notification {
  background-color: #fff !important;
}

.custom-notification .ant-notification-notice-content div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.custom-notification .ant-notification-notice-content div div {
  margin-left: 48px !important;
}

.custom-notification .ant-notification-notice-message {
  color: #000;
  font-size: 16px !important;
  font-weight: 400;
}

.custom-notification .ant-notification-notice-description {
  color: #252525;
  font-size: 13px !important;
  font-weight: 400;
  line-height: 14px;
}

.custom-notification .ant-notification-notice-close-x {
  color: #000;
}

.custom-popover .ant-popover-inner {
  border-radius: 8px;
  padding: 8px;
}


/***CustomProgress Ant Design****/

.custom-progress.dashboard .ant-progress-text {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1280px) {

  .custom-progress.dashboard,
  .custom-progress.dashboard svg,
  .custom-progress.dashboard .ant-progress-inner {
    width: 325px !important;
  }

  .custom-progress.dashboard {
    height: 420px !important;
  }

  .custom-progress.dashboard .ant-progress-text {
    padding-bottom: 84px !important;
  }

  .custom-progress.dashboard .ant-progress-text span {
    font-size: 48px !important;
  }
}


/***CustomSpin Ant Design***/

.custom-spin__wrapper .ant-spin,
.custom-spin__wrapper .ant-spin-container.ant-spin-blur {
  height: 100%;
  max-height: none !important;
}

.custom-spin__wrapper .ant-spin-text {
  font-size: 18px;
}

.custom-spin__wrapper__fullscreen {
  height: 100vh !important;
  width: 100vw !important;
}

.custom-spin__wrapper__fullscreen .custom-spin__containt-wrapper {
  height: calc(100vh - 64px) !important;
}

.custom-spin__wrapper__fullscreen .ant-spin {
  height: 200px !important;
  max-height: 200px !important;
  top: 50% !important;
  left: 50% !important;
  margin-top: 18px;
  transform: translate(-50%, -50%);
}

.custom-spin__wrapper.ant-spin {
  position: static !important;
  display: flex !important;
}

.ant-spin-nested-loading:has(.custom-spin)>div>.ant-spin .ant-spin-dot {
  position: relative !important;
  top: 0;
  left: 0;
  place-self: center;
  margin: 0 !important;
}

.ant-spin-nested-loading:has(.custom-spin) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
}

.ant-spin-nested-loading:has(.custom-spin)>.ant-spin-container::after {
  background: none !important;
}

.ant-spin-nested-loading:has(.custom-spin)>.ant-spin-container.ant-spin-blur {
  opacity: 1 !important;
}

.custom-spin__containt-wrapper {
  position: relative;
  transition: opacity 0.3s ease-in-out;
  height: 100%;
  width: 100%;
  min-width: 88px;
}

.custom-spin__logo {
  z-index: 100;
  border-radius: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
}

/*Estilo par que sea en los wrapper de las sections**/

.custom-spin {
  display: grid !important;
  place-content: center;
}

.custom-spin__wrapper.normal .ant-spin-container.ant-spin-blur {
  height: 100% !important;
  width: 100% !important;
  max-height: none !important;
}

.custom-spin__wrapper.normal.section,
.custom-spin__wrapper.normal.section .ant-spin-container.ant-spin-blur,
.custom-spin__wrapper.section .ant-spin,
.custom-spin__wrapper.section .ant-spin-container.ant-spin-blur {
  height: calc(100vh - 64px) !important;
}

.custom-spin__wrapper.normal {
  width: 100%;
  height: 100% !important;
}

.custom-spin__wrapper .ant-spin-text,
.custom-spin__wrapper__fullscreen .ant-spin-text {
  font-size: 18px;
}

.custom-spin .ant-spin-text {
  position: relative !important;
  width: 300px !important;
  color: #252525;
  max-height: 39px;
}

.custom-spin.custom-icon .ant-spin-text {
  top: 50% !important;
}

.custom-spin__wrapper__fullscreen{
  position: relative;
}

.custom-modal .ant-modal-content {
  border-radius: 10px;
}

.custom-modal .ant-modal-body {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 600px;
}

.custom-modal .ant-modal-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 28px 8px;
  margin: 0 24px;
}

.custom-modal .ant-modal-footer {
  border-top: none;
  padding: 16px;
}

/***Estilos del Custom Popconfirm de ant design***/
.custom-popover .ant-popover-inner-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.custom-popover .ant-popover-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.custom-popover .ant-popover-message svg {
  color: rgba(234, 94, 100, 1);
}

.custom-popover .ant-popover-message-title {
  padding-left: 8px;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.custom-popover .ant-popover-inner {
  border-radius: 16px;
  padding: 16px;
}

.custom-popover .ant-popover-buttons {
  display: flex;
  flex-direction: row-reverse;
  gap: 14px;
  justify-content: center;
  align-items: center;
}

.custom-popover__ok-button {
  width: 172px;
  height: 34px !important;
  background-color: #fff !important;
  border: 1px solid var(--primary) !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.custom-popover__ok-button span {
  color: var(--primary);
}

.custom-popover__cancel-button {
  width: 172px;
  height: 34px !important;
  background-color: #fff !important;
  border: 1px solid #ff2e2e !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.custom-popover__cancel-button span {
  color: #ff2e2e;
}


.navigation-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  white-space: 'pre-wrap';
  height: 42px;
}