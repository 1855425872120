.error-page {
    min-height: 100dvh;
    overflow-y: auto;
    overflow-x: hidden;
    background: linear-gradient(#0847a6, #1264e0, #4a85de);
    color: #fff;
    position: relative;
    z-index: 1;
    padding-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error-page img {
    position: absolute;
}

.error-page__subcontainer-1 {
    position: absolute;
    width: 100%;
    bottom: 20%;
}

.error-page__mountains-img {
    width: 100%;
    height: 50%;    
    bottom: 5%;
    object-fit: cover ;
    object-position: top;
}

.error-page__ground-img {
    bottom: 0;
    width: 100%;
    height: 30%;
    object-fit: cover;
    object-position: top;
    z-index: 10;
}

.error-page__error-icon {
    top: 45.50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.error-page-container{
    z-index: 15;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error-page-container h1{
    color: #fff;
    font-weight: 600;
    font-size: 55px;
}

.error-page-container button{
    margin-top: 10%;
    height: 40px;
    width: 183px;
    border-radius: 50px;
    background-color: #fff;
    color: #252525;
    text-transform: uppercase;
    border: 1px solid var(--primaryminus4);
}


@media screen and (min-width: 1280px ) {
    .error-page__heaven-img {
        height: auto;
        min-height: 25%;
    }

    .error-page__error-icon {
        width: 50%;
        max-width: 50%;
        min-height: 85%;
        top: 40.5%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .error-page__mountains-img {
        height: 60%;
        bottom: 0%;
    }

    .error-page__ground-img {
        bottom: 0%;
        width: 100%;
        min-height: 35%;
        z-index: 10;
    }   

    .error-page-container{
        top: 75%;
        left: 50%;
    }
    .error-page-container button{
        margin-top: 25%;
    }
    
}
