.custom-tab .ant-tabs-nav {
    border-bottom: 1px solid #829A9E;
}

.custom-tab .ant-tabs-nav .ant-tabs-tab {
    background-color: transparent !important;
    border: none !important;
}

.custom-tab .tab-title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #232C4A;
}

.custom-tab .ant-tabs-tab {
    width: 250px;
    display: flex;
    justify-content: center;
}

.custom-tab .ant-tabs-ink-bar {
    background: var(--primary) !important;
    height: 5px !important;
}

/*** Tabs Ant Desing ***/
.custom-tabs .ant-tabs-nav .ant-tabs-tab {
    border-radius: 15px 15px 0 0 !important;
    margin: 0 10px 0 0 !important;
    padding: 11px 25px !important;
    border: 1px solid #ffffff !important;
}

.custom-tabs .ant-badge:not(.ant-badge-not-a-wrapper) {
    margin-right: 0;
    padding-right: 0.3rem;
}

.custom-tabs .card-container .ant-tabs-card .ant-tabs-tab {
    border-bottom: 1px solid #000 !important;
}

.custom-tabs .ant-tabs-tab {
    background: #D5DAE3 !important;
}

.custom-tabs .ant-tabs-tab:hover {
    color: var(--primary);
}

.custom-tabs .ant-tabs-tab-active {
    background: #fff !important;
    color: #000 !important;
}

.custom-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary);
}

.custom-tabs .ant-badge:not(.ant-badge-not-a-wrapper):hover {
    color: var(--primary);
}

.custom-tabs .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background-color: var(--primary);
}

/* Tabs variante 1 */
.custom-tabs-variant-1 .ant-tabs-tab {
    justify-content: center;
    background-color: transparent;
    min-width: 300px;
    color: #252525;
    font-size: 16px;
    font-weight: 500;
}

.custom-tabs-variant-1 .ant-tabs-tab-btn {
    color: #000 !important;
}

.custom-tabs-variant-1 .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary) !important;
}

.custom-tabs-variant-1 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    height: 5px !important;
    background-color: var(--primary);
}

/* Tabs variante 2 */
.custom-tabs-variant-2 .ant-tabs-nav .ant-tabs-tab {
    border-radius: 8px 8px 0 0 !important;
    margin: 0 10px 0 0 !important;
    padding: 12px 6px !important;
    border: 1px solid #ffffff;
    font-weight: 500;
}

.custom-tabs-variant-2 .ant-badge:not(.ant-badge-not-a-wrapper) {
    margin-right: 0;
    padding-right: 0.3rem;
}

.custom-tabs-variant-2 .card-container .ant-tabs-card .ant-tabs-tab {
    border-bottom: 0px solid #000 !important;
}

.custom-tabs-variant-2 .ant-tabs-tab {
    background: #D5DAE3 !important;
}

.custom-tabs-variant-2 .ant-tabs-tab:hover {
    color: var(--primary);
}

.custom-tabs-variant-2 .ant-tabs-tab-active {
    background: #fff !important;
    color: #000 !important;
}


.custom-tabs-variant-2 .ant-tabs-tab .ant-tabs-tab-btn {
    padding-left: 24px !important;
    padding-right: 24px;
}

.custom-tabs-variant-2 .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary);
    border-radius: 2px;
    border-left: 4px solid var(--primary) !important;

}

.custom-tabs-variant-2 .ant-badge:not(.ant-badge-not-a-wrapper):hover {
    color: var(--primary);
}

.custom-tabs-variant-2 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none;
}

/* Tabs variante 3 */
.custom-tabs-variant-3 .ant-tabs-tab {
    justify-content: center;
    background-color: transparent;
    min-width: 280px;
    color: #252525;
    font-size: 14px;
    font-weight: 500;
}

.custom-tabs-variant-3 .ant-tabs-tab-btn {
    color: #000 !important;
}

.custom-tabs-variant-3 .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary) !important;
}

.custom-tabs-variant-3 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    height: 5px !important;
    background-color: var(--primary);
}

.custom-tabs-variant-3 .ant-tabs-nav-wrap{
    border-bottom: 1px solid #829a9e;
}

/* Tabs variante 4 */
.custom-tabs-variant-4 .ant-tabs-tab {
    justify-content: center;
    background-color: transparent;
    min-width: 127px;
    color: #404040;
    font-size: 14px;
    font-weight: 400;
}

.custom-tabs-variant-4 .ant-tabs-tab-btn {
    color: #404040 !important;
}

.custom-tabs-variant-4 .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary) !important;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
}

.custom-tabs-variant-4 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    height: 5px !important;
    background-color: var(--primary);
}