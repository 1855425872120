.wrapper-login {
  position: absolute;
  height: 100%;
  width: 100%;  
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  padding: 16px 0;
}

.wrapper-login .login-box {
  max-width: 500px;
  width: 100%;
  min-width: 300px;
  border-radius: 6px;
  background: linear-gradient(
    180deg,
    rgb(29, 11, 70, 0.5) 0%,
    rgb(36, 19, 90, 0.5) 50.08%,
    rgb(37, 29, 117, 0.5) 100%
  );
  background-blend-mode: normal;
  box-shadow: 0px 2px 4px 2px rgba(17, 7, 43, 0.8);
  height: 550px;
  margin: auto 0;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 50px;
}

.wrapper-login .login-box .logo {
  min-width: 100px;
  width: initial;
}

.wrapper-login .login-box form {
  margin-top: 60px;
}

.wrapper-login .login-box .btn-login {
  width: 100%;
  border-radius: 26px;
  height: 46px;
  text-transform: uppercase;
}


#login-slogan {

}

.wrapper-login .input-field label {
  color: #fff !important;
}

.wrapper-login .copyright {
  color: #fff;
  text-align: center;
  margin-top: 120px;
  padding-bottom: 16px;
  font-size: 12px;
}

.wrapper-login .copyright span {
  color: #0ac598;
}

.wrapper-login .ant-input-affix-wrapper {
  background: transparent !important;
  border: none !important;
  border-bottom: 1px solid #0ac598 !important;
  color: #0ac598;
}

.wrapper-login .ant-input-affix-wrapper .ant-input-prefix span {
  color: #0ac598;
}

.wrapper-login .ant-input-affix-wrapper input {
  background: transparent;
}

.wrapper-login .ant-input {
  color: #0ac598 !important;
  padding-left: 15px !important;
}

.wrapper-login .ant-input-suffix svg {
  color: #0ac598;
}

.wrapper-login .form-group {
  margin-bottom: 30px;
}
