/* Table */

.custom-table-variant-1 .ant-table{
  margin-top: 8px;
  padding: 16px 24px;
  background-color: #fff !important;
  border-radius: 6px;
}

.custom-table-variant-1 .ant-table-header .ant-table-cell,
.custom-table-variant-1 .ant-table-thead .ant-table-cell,
.custom-ant-table .ant-table-header .ant-table-cell,
.custom-ant-table .ant-table-thead>tr>th {
  background: #fff !important;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  color: #232323;
  text-align: center;
}

.custom-table-variant-1 .ant-table-tbody,
.custom-ant-table .ant-table-tbody {
  background-color: #fff;
}

.custom-table-variant-1 .ant-table-row,
.custom-ant-table .ant-table-row {
  font-size: 12px;
  font-weight: 400;
  color: #232323;
  text-align: center;
}

.custom-table-variant-1 .ant-table-row:nth-child(odd),
.custom-ant-table .ant-table-row:nth-child(odd) {
  background-color: #fff !important;
}

.custom-table-variant-1 .ant-table-row:nth-child(even),
.custom-ant-table .ant-table-row:nth-child(even) {
  background-color: #f3f3f3 !important;
}

.custom-table-variant-1 .ant-pagination.ant-table-pagination li {
    display: none;
}

.custom-table-variant-1 .ant-pagination.ant-table-pagination li:first-child,
.custom-table-variant-1 .ant-pagination.ant-table-pagination li:nth-child(2),
.custom-table-variant-1 .ant-pagination.ant-table-pagination li:last-child {
    display: block !important;
}

.custom-table-variant-1 .ant-pagination.ant-table-pagination li:nth-child(2) button,
.custom-table-variant-1 .ant-pagination.ant-table-pagination li:last-child button {
    background-color: transparent;
    border: none;
}

/* Custom table variant 2 */

.custom-table-variant-2 .ant-table-thead>tr>th:first-child {
    border-top-left-radius: 8px !important;
}

.custom-table-variant-2 .ant-table-thead>tr>th:last-child {
    border-top-right-radius: 8px !important;
}

.custom-table-variant-2 .ant-table-thead>tr>th {
    background-color: var(--primaryminus3) !important;
}

.custom-table-variant-2 .ant-table-tbody {
    background-color: #fff;
}

.custom-table-variant-2 .ant-table-cell {
    font-size: 14px !important;
}

.custom-table-variant-2 .ant-table-thead .ant-table-cell {
    font-size: 11px !important;
}

.custom-table-variant-2 .ant-table-row:nth-child(even) {
    background-color: #fff !important;
}

.custom-table-variant-2 .ant-table-row:nth-child(odd) {
    background-color: #f5f9f9 !important;
}

.custom-table-variant-2 .ant-pagination.ant-table-pagination li {
    display: none;
}

.custom-table-variant-2 .ant-pagination.ant-table-pagination li:first-child,
.custom-table-variant-2 .ant-pagination.ant-table-pagination li:nth-child(2),
.custom-table-variant-2 .ant-pagination.ant-table-pagination li:last-child {
    display: block !important;
}

.custom-table-variant-2 .ant-pagination.ant-table-pagination li:nth-child(2) button,
.custom-table-variant-2 .ant-pagination.ant-table-pagination li:last-child button {
    background-color: transparent;
    border: none;
}

/* Custom table variant 3 */

.custom-table-variant-3 .ant-table-thead>tr>th {
  background-color: var(--primaryminus3) !important;
}

.custom-table-variant-3 .ant-table-row {
  background-color: #FFFFFF!important;
}

.custom-table-variant-3 .ant-table-thead>tr>th {
  background-color: var(--primaryminus3) !important;
}

.custom-table-variant-3 .ant-table-thead tr {
  height: 72px;
}

.custom-table-variant-3 .ant-table-thead tr th:first-child {
  border-top-left-radius: 12px !important;
}

.custom-table-variant-3 .ant-table-thead tr th:last-child {
  border-top-right-radius: 12px !important;
}

.custom-table-variant-3 .ant-table-tbody tr:last-child td:first-child {
  border-bottom-left-radius: 12px !important;
}

.custom-table-variant-3 .ant-table-tbody tr:last-child td:last-child {
  border-bottom-right-radius: 12px !important;
}

.custom-table-variant-3 .ant-table-cell {
  font-size: 14px !important;
  min-height: 72px !important;
}

.custom-table-variant-3 .ant-table-thead .ant-table-cell {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.custom-table-variant-3 .ant-table-row:nth-child(odd) {
  background-color: #fff !important;
}

.custom-table-variant-3 .ant-table-row:nth-child(even) {
  background-color: #fff !important;
}

.custom-table-variant-3 .ant-pagination.ant-table-pagination li {
  display: none;
}

.custom-table-variant-3 .ant-pagination.ant-table-pagination li:first-child,
.custom-table-variant-3 .ant-pagination.ant-table-pagination li:nth-child(2),
.custom-table-variant-3 .ant-pagination.ant-table-pagination li:last-child {
  display: block !important;
}

.custom-table-variant-3 .ant-pagination.ant-table-pagination li:nth-child(2) button,
.custom-table-variant-3 .ant-pagination.ant-table-pagination li:last-child button {
  background-color: transparent;
  border: none;
}

/* Custom table variant 4 (detalle de un checklist)*/

.custom-table-variant-4 table {
  border-collapse: separate !important;
  border-spacing: 0 20px !important;
}

.custom-table-variant-4 tbody {
  position: relative;
  bottom: 1.5rem;
}

.custom-table-variant-4 tr {
  height: 80px;
}

.custom-table-variant-4 .ant-table-thead>tr>th,
.ant-table {
  background-color: transparent !important;
}

.custom-table-variant-4 .ant-table-tbody>tr {
  background-color: #fff;
  box-shadow: 0px 6px 7px 0px #9AB9D440;
  padding-bottom: 2rem;
}

.custom-table-variant-4 .ant-table-tbody>tr>td:first-child {
  border-radius: 8px 0 0px 8px;
}

.custom-table-variant-4 .ant-table-tbody>tr>td:last-child {
  border-radius: 0px 8px 8px 0px;
}

.custom-table-variant-4 .ant-pagination.ant-table-pagination li {
  display: none;
}

.custom-table-variant-4 .ant-pagination.ant-table-pagination li:first-child,
.custom-table-variant-4 .ant-pagination.ant-table-pagination li:nth-child(2),
.custom-table-variant-4 .ant-pagination.ant-table-pagination li:last-child {
  display: block !important;
}

.custom-table-variant-4 .ant-pagination.ant-table-pagination li:nth-child(2) button,
.custom-table-variant-4 .ant-pagination.ant-table-pagination li:last-child button {
  background-color: transparent;
  border: none;
}

/* Variante de columna background gris */
.custom-table-column-color-variant-1 {
  background-color: #E7E7E7 !important
}

/* Hover para las rows */
.row-className :hover{
  cursor: pointer!important;
}