.container-load {

  width: 100%;
  height: 50%;
  position: relative;
}

.child {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50% !important;
  left: 50%;

}

.info-window {
  width: 120px;
  height: 60px;
}

.info-window .title {
  color: #404040;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.info-window .description {
  color: #404040;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}

.custom-navbar-menu {
  padding: 0 !important;
  min-width: 180px;
}

.custom-navbar-menu>li {
  min-height: 40px;
}

.custom-navbar-menu>li button{
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.custom-navbar-dropdown{
  border: none;
  background-color: transparent;
  height: 47px;
}